import React, { useMemo, useCallback } from 'react'
import { IonActionSheet } from '@ionic/react'
import {
  pencilOutline,
  checkmarkCircleOutline,
  documentAttachOutline,
  documentTextOutline,
  duplicateOutline,
  locationOutline,
  trailSignOutline,
  eyeOutline,
} from 'ionicons/icons'
import { useHistory } from 'react-router-dom'

import { useCurrentLocale } from 'context/LocaleContext'
import useTranslation from 'hooks/useTranslation'
import useToast from 'hooks/useToast'
import useModel from 'hooks/useModel'
import { useSubmission } from 'hooks/submission'
import { useInspectionModels } from 'hooks/useModelList'
import useInterventionModels from 'hooks/useInterventionModels'

import {
  makeAssetRevisionSubmission,
  saveSubmission,
} from 'services/submissions'

import { FullAsset } from 'core/Assets'
import { SubmissionStatus, SubmissionSourceType } from 'core/Submission'
import { canBeUpdated, extractTitle } from 'core/ContentModel'
import { getLocalizedProperty } from 'core/common'
import { createAssetCopy } from 'core/mappers'
import openMap from 'core/openMap'
import generateKml from 'core/generateKml'

const getLocation = (asset: FullAsset) => asset.location.coordinates

interface RemoteAssetMenuProps {
  asset: FullAsset
  onDidDismiss: () => void
}

const RemoteAssetMenu: React.FC<RemoteAssetMenuProps> = (props) => {
  const { asset, onDidDismiss } = props

  const locale = useCurrentLocale()
  const t = useTranslation()
  const Toast = useToast()

  const { push } = useHistory()

  // model
  const { data: model, isLoading: isLoadingModel } = useModel(asset.assetType)

  // revision
  const {
    data: revision,
    refetch: refetchRevision,
    isLoading: isLoadingRevision,
  } = useSubmission(asset.revisionSubmissionId!, {
    enabled: Boolean(asset.revisionSubmissionId),
  })

  // inspection models
  const { data: inspectionModels, isLoading: isLoadingInspectionModels } =
    useInspectionModels(asset.assetType)

  // intervention models
  const { data: interventionModels, isLoading: isLoadingInterventionModels } =
    useInterventionModels(asset.assetType)

  // misc
  const assetTitle = extractTitle(model, asset)
  const modelLabel = model ? getLocalizedProperty(locale, 'label', model) : ''

  // handlers
  const handleCreateAssetRevision = useCallback(async () => {
    const { assetType, revisionSubmissionId } = asset
    let submissionId = revisionSubmissionId

    // NOTE first time updating the asset
    if (!submissionId) {
      const submission = await makeAssetRevisionSubmission(asset)
      submissionId = submission.id
    }

    push(`/update-inventory/${submissionId}/${assetType}`)
  }, [asset, push])

  const handleViewAsset = useCallback(() => {
    const { assetType, id } = asset
    push(`/inventory/${id}/${assetType}`, {
      initialData: createAssetCopy(asset),
      readOnly: true,
    })
  }, [asset, push])

  const handleFinishEdition = useCallback(async () => {
    await saveSubmission({
      ...revision!,
      status: SubmissionStatus.finished,
    })

    // NOTE make sure this and the corresponding `AssetItem` get updated
    refetchRevision()
  }, [revision, refetchRevision])

  const handleCreateInspection = useCallback(async () => {
    // NOTE this is unreachable unless `inspectionModels` is available (see the
    // guard below)

    if (inspectionModels!.length > 1) {
      push(`/choose-inspection/${asset.id}`, {
        sourceType: SubmissionSourceType.Remote,
      })
    } else {
      const modelId = inspectionModels![0].id

      push(`/new-inspection/${asset.id}/${modelId}`)
    }
  }, [asset, inspectionModels, push])

  const handleCreateIntervention = useCallback(async () => {
    if (interventionModels!.length > 1) {
      push(`/choose-intervention/${asset.id}`, {
        sourceType: SubmissionSourceType.Remote,
      })
    } else {
      const modelId = interventionModels![0].id

      push(`/new-intervention/${asset.id}/${modelId}`)
    }
  }, [asset, interventionModels, push])

  const handleDuplicateNewAsset = useCallback(async () => {
    const copy = createAssetCopy(asset)
    await saveSubmission(copy)
    // NOTE submissions should be refetched after creating a copy of the asset,
    // which could be achieved through the `refetch` function of react-query,
    // or by calling `queryCache.invalidateQueries`
    // NOTE however, we are doing this operation in the parent component for now
  }, [asset])

  const handleDownloadKml = useCallback(async () => {
    try {
      const [lng, lat, alt] = getLocation(asset)

      // REVIEW can we be certain the (remote) asset will have coordinates?
      if (lat == null || lng == null) {
        Toast.error(t('notifications.locationError'))
        return
      }

      await generateKml({
        modelLabel,
        sourceId: asset.id,
        title: assetTitle,
        lat,
        lng,
        alt,
      })
    } catch (error) {
      console.error('Error generating zip with submissions:', error)
      Toast.error(t('notifications.downloadError'))
    }
  }, [t, Toast, asset, assetTitle, modelLabel])

  const handleNavigateToLocation = useCallback(async () => {
    const [lng, lat] = getLocation(asset)

    // REVIEW can we be certain the (remote) asset will have coordinates?
    if (lat == null || lng == null) {
      Toast.error(t('notifications.locationError'))
      return
    }

    openMap(lat, lng)
  }, [t, Toast, asset])

  const buttons = useMemo(() => {
    const buttons = []
    if (!revision?.status)
      buttons.push({
        text: t('assetMenu.view'),
        icon: eyeOutline,
        handler: handleViewAsset,
      })

    if (model && canBeUpdated(model)) {
      buttons.push({
        text: t('assetMenu.edit'),
        icon: pencilOutline,
        handler: handleCreateAssetRevision,
      })
    }

    if (
      asset.revisionSubmissionId &&
      revision?.status &&
      revision?.status !== SubmissionStatus.finished
    ) {
      buttons.push({
        text: t('assetMenu.finish'),
        icon: checkmarkCircleOutline,
        handler: handleFinishEdition,
      })
    }

    if (inspectionModels?.length) {
      buttons.push({
        text: t('assetMenu.inspect'),
        icon: documentAttachOutline,
        handler: handleCreateInspection,
      })
    }

    if (interventionModels?.length) {
      buttons.push({
        text: t('assetMenu.intervention'),
        icon: documentTextOutline,
        handler: handleCreateIntervention,
      })
    }

    buttons.push(
      {
        // REVIEW can assets always be duplicated?
        text: t('assetMenu.duplicate'),
        icon: duplicateOutline,
        handler: handleDuplicateNewAsset,
      },
      {
        text: t('assetMenu.downloadKml'),
        icon: locationOutline,
        handler: handleDownloadKml,
      },
      {
        text: t('assetMenu.directions'),
        icon: trailSignOutline,
        handler: handleNavigateToLocation,
      },
    )

    return buttons
  }, [
    t,
    model,
    asset,
    revision,
    inspectionModels,
    interventionModels,
    handleFinishEdition,
    handleViewAsset,
    handleCreateAssetRevision,
    handleCreateInspection,
    handleCreateIntervention,
    handleDuplicateNewAsset,
    handleDownloadKml,
    handleNavigateToLocation,
  ])

  return (
    <IonActionSheet
      isOpen={
        !isLoadingModel &&
        !isLoadingInspectionModels &&
        !isLoadingInterventionModels &&
        !isLoadingRevision
      }
      onDidDismiss={onDidDismiss}
      buttons={buttons}
    />
  )
}

export default RemoteAssetMenu
